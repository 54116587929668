import React from "react";

import ExploreServices from "./ExploreServices";


const CarpenterNearMe = () => {

  return (
    <div>
      <section>
        <img src="../joboyimages/services/header-bg.jpg" className="img-fluid" alt="Expert Carpenters Near You" />
      </section>
      <section className="pb40">
        <div className="container text-center pb40">
          <h1 className="serviceh1">BOOK EXPERIENCED CARPENTERS NEAR YOU</h1>
          <p>Expert carpenters near you, at your convenient time. Book now for expert wood work, furniture repairs, and other carpentry work, from verified providers, at low costs.</p>
        </div>
        <div className="container pt40 pb40">
          <h3 className="text-center">QUICK LINKS TO CARPENTRY SERVICES IN CITIES NEAR YOU</h3>
          <div className="row justify-content-center">
            <ul className="joboy-city">
              <li> <a href="https://joboy.in/services/kochi/carpenter-in-kochi"><img src="../joboyimages/Kochi.png" className="img-icon" alt="Best Capenters in Kochi" />
                <div>Carpenters in </div>
                <div>Kochi</div>
              </a> </li>
              <li> <a href="https://joboy.in/services/trivandrum/carpenter-in-trivandrum"><img src="../joboyimages/trivandrum.png" className="img-icon" alt="Best Carpenters in Trivandrum" />
                <div>Carpenters in</div>
                <div>Trivandrum</div>
              </a> </li>
              <li> <a href="https://joboy.az/services/baku/handyman-service-in-baku"><img src="../joboyimages/Baku.png" className="img-icon" alt="Best Carpenters in Baku" />
                <div>Carpenters in </div>
                <div>Baku</div>
              </a> </li>
              <li> <a href="https://joboy.ae/service/details/carpentry-service-dubai"><img src="../joboyimages/dubai.png" className="img-icon" alt="Best Carpenters in Dubai" />
                <div>Carpenters in</div>
                <div>Dubai</div>
              </a> </li>
              <li> <a href="https://joboy.ae/service/details/carpentry-service-abudhabi"><img src="../joboyimages/Abudhabi.png" className="img-icon" alt="Best Carpenters in Abu Dhabi" />
                <div>Carpenters in </div>
                <div>Abu Dhabi</div>
              </a></li>
              <li> <a href="https://joboy.ae/service/details/carpentry-service-abudhabi"><img src="../joboyimages/hyderabad.png" className="img-icon" alt="Best Carpenters in Hyderabad" />
                <div>Carpenters in</div>
                <div>Hyderabad</div>
              </a></li>

            </ul>
          </div>
        </div>
      </section>
      <section>
        <div className="container blue-bg pt40 pb40">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h5 className="download-text white-font"> GET A BETTER EXPERIENCE ON THE JOBOY MOBILE APP. DOWNLOAD NOW!</h5>
              </div>
              <div clas="col-md-6">
                <div className="row">
                  <div className="col-md-6 form-inline">
                    <div className="form-group p-1"> <a href="https://apps.apple.com/us/app/joboy-home-services-repairs/id1270174460" className="btn btn-market btn--with-shadow"> <img className="utouch-icon" src="../joboyimages/ios.png" alt="Joboy on Apple app store" />
                      <div className="text"> <span className="sup-title">Download from</span> <span className="title">App Store</span> </div>
                    </a>
                    </div>
                  </div>
                  <div className="col-md-6 form-inline">
                    <div className="form-group p-1"> <a href="https://play.google.com/store/apps/details?id=com.serville.joboy" className="btn btn-market btn--with-shadow"> <img className="utouch-icon" src="../joboyimages/google-play.svg" alt="Joboy on Google play store" />
                      <div className="text"> <span className="sup-title">Download from</span> <span className="title">Google Play</span> </div>
                    </a> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb40 pt40">
        <div className="container text-center">
          <h2>EXPERT CARPENTER SERVICES IN YOUR CITY</h2>
          <p className="mb-5">Our experienced carpenters can take care of all your carpentry issues, from fixing a lock to putting together kitchen cabinets, and more. They can also help with assebling your furniture, polishing used furniture, or periodic maintenance for your home, at affordable prices, using best quality materials.</p>

          <div className="row justify-content-center">
            <div className="col-lg-12">

            </div>
            <div className="col-lg-4 col-md-6">
              <div className="featured-item pd-bottom-30">
                <div className="thumb">
                  <img src="../joboyimages/services/carpenter-1.jpg" alt="Kitchen cabinet making" />
                </div>
                <div className="featured-item-meta">
                  <h3>KITCHEN CABINETS</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="featured-item pd-bottom-30">
                <div className="thumb">
                  <img src="../joboyimages/services/carpenter-2.jpg" alt="wood work repairs" />
                </div>
                <div className="featured-item-meta">
                  <h3>DRILLING & HANGING</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="featured-item pd-bottom-30">
                <div className="thumb">
                  <img src="../joboyimages/services/carpenter-3.jpg" alt="furniture repairs" />
                </div>
                <div className="featured-item-meta">
                  <h3>FURNITURE REPAIRS</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section>
        <div className="container text-center">
          <div className="row">
            <div className="col-sm-4 pt-5">
              <ul className="features-list">
                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Easy-Scheduling.png" alt="Easy to book" /> </div>
                <li className="single-feature-item">
                  <h5> Your Convenient Time</h5>
                  <span className="text">Your time is precious. Leave the tough work to us.</span> </li>
                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Verified-Professionals.png" alt="Background verified" /> </div>
                <li className="single-feature-item">
                  <h5>Verified Service Partners</h5>
                  <span className="text">Your safety and security is important for us.</span> </li>
                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/ServiceWarranty.png" alt="Warranty on services" /> </div>
                <li className="single-feature-item">
                  <h5>Service Warranty</h5>
                  <span className="text">Get upto 60 days service warranty ensuring peace of mind.</span> </li>
                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/TransparentPricing.png" alt="Pre-defined pricing" /> </div>
                <li className="single-feature-item">
                  <h5>Transparent Pricing</h5>
                  <span className="text">Know in advance what you are paying for.</span> </li>
                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Secure-Payments.png" alt="Many payment options" /> </div>
                <li className="single-feature-item">
                  <h5>Online Payments </h5>
                  <span className="text">Don't carry cash? No problem, pay online.</span> </li>
                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Support.png" alt="Best customer care" /> </div>
                <li className="single-feature-item">
                  <h5>24 x 7 Support</h5>
                  <span className="text">Reach us anytime. We are here to support.</span> </li>
              </ul>
            </div>
            <div className="col-sm-8 text-left">
              <div className="p-5">
                <h3>Why book Carpenters from Joboy?</h3>
                <div>
                  <p>We bring you background verified, experienced, carpenters, at your most convenient time, to where you want them, after validation of their skill and experience. You can be sure that we will give you the best services, since our partners have many years of experience. Our carpentry service costs are affordable, and you will be given a quote in advance. You also have the option to pay by cash or pay online. Rest assured, we bring you the best experts for your home repairs.</p>
                  <p className="mt-3 mb-5">Book a service online easily, sit back and relax, and let our experts take care of your home.</p>
                </div>
                <div className="clearfix"></div>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="td-list mb-5">
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Professional Carpenters" /></i>Professional Carpenters</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Wood work experts" /></i>Wood Work Experts</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Furniture assebling" /></i>Furniture Assembly</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Wooden shelves making" /></i>Wooden Shelves</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Kitchen shelves making" /></i>Kitchen Shelves</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Furniture polishing" /></i>Furniture Polishing</li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="td-list mb-5">
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Furniture repairs" /></i>Furniture Repairs</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Drilling & Hanging" /></i>Wall Drilling & Hanging</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Door handle repairs" /></i>Handle Replacements</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Door lock repairs" /></i>Lock Replacements</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Door hinges fixing" /></i>Hinges Repairs</li>
                      <li><i><img src="../joboyimages/tck-mark.png" alt="Accessories installation" /></i>Accessories Installation</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ExploreServices />
    </div>
  );
}

export default CarpenterNearMe;
import React from "react";

import ExploreServices from "./ExploreServices";


const PlumberNearMe = () => {
    return (
        <div>
            <section>
                <img src="../joboyimages/services/plumber-hero.jpg" className="img-fluid" alt="Expert Plumbers Near You" />
            </section>

            <section>
                <div className="container text-center pb40">
                    <h1 className="serviceh1">FIND EXPERIENCED PLUMBERS NEAR YOU</h1>
                    <p>Expert plumbing repairs near you, at your convenient time. Book now for on-demand 24/7 plumber services, with service warranty, and low costs.</p>
                </div>
                <div className="container pt40 pb40">
                    <h3 className="text-center">QUICK LINKS TO PLUMBER SERVICES IN CITIES AROUND YOU</h3>
                    <div className="row justify-content-center">
                        <ul className="joboy-city">
                            <li> <a href="https://joboy.in/services/kochi/plumber-in-kochi"><img src="../joboyimages/Kochi.png" className="img-icon" alt="Best Plumbers in Kochi" />
                                <div>Plumbers in </div>
                                <div>Kochi</div>
                            </a> </li>
                            <li> <a href="https://joboy.in/services/trivandrum/plumber-in-trivandrum"><img src="../joboyimages/trivandrum.png" className="img-icon" alt="Best Plumbers in Trivandrum" />
                                <div>Plumbers in</div>
                                <div>Trivandrum</div>
                            </a> </li>
                            <li> <a href="https://joboy.az/services/baku/plumber-service-in-baku"><img src="../joboyimages/Baku.png" className="img-icon" alt="Best Plumbers in Baku" />
                                <div>Plumbers in </div>
                                <div>Baku</div>
                            </a> </li>
                            <li> <a href="https://joboy.ae/service/details/plumbing-service-dubai"><img src="../joboyimages/dubai.png" className="img-icon" alt="Best Plumbers in Dubai" />
                                <div>Plumbers in</div>
                                <div>Dubai</div>
                            </a> </li>
                            <li> <a href="https://joboy.in/services/delhi-ncr/plumber-in-delhi"><img src="../joboyimages/Delhi.png" className="img-icon" alt="Best Plumbers in Delhi" />
                                <div>Plumbers in </div>
                                <div>Delhi</div>
                            </a></li>
                            <li> <a href="https://joboy.in/services/mumbai/plumber-in-mumbai"><img src="../joboyimages/mumbai.png" className="img-icon" alt="Best Plumbers in Mumbai" />
                                <div>Plumbers in</div>
                                <div>Mumbai</div>
                            </a></li>
                            <li> <a href="https://joboy.ae/service/details/plumbing-service-abudhabi"><img src="../joboyimages/Abudhabi.png" className="img-icon" alt="Best Plumbers in Abu Dhabi" />
                                <div>Plumbers in </div>
                                <div>Abu Dhabi</div>
                            </a></li>
                            <li> <a href="https://joboy.in/services/chennai/plumber-in-chennai"><img src="../joboyimages/chennai.png" className="img-icon" alt="Best Plumbers in Chennai" />
                                <div>Plumbers in </div>
                                <div>Chennai</div>
                            </a></li>
                            <li> <a href="https://joboy.ca/services/calgary/plumber-service-in-calgary"><img src="../joboyimages/Calgary.png" className="img-icon" alt="Best Plumbers in Calgary" />
                                <div>Plumbers in </div>
                                <div>Calgary</div>
                            </a></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section>
                <div className="container blue-bg pt40 pb40">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="download-text white-font"> GET A BETTER EXPERIENCE ON THE JOBOY MOBILE APP. DOWNLOAD NOW!</h5>
                            </div>
                            <div clas="col-md-6">
                                <div className="row">
                                    <div className="col-md-6 form-inline">
                                        <div className="form-group p-1"> <a href="https://apps.apple.com/us/app/joboy-home-services-repairs/id1270174460" className="btn btn-market btn--with-shadow"> <img className="utouch-icon" src="../joboyimages/ios.png" alt="Joboy on Apple app store" />
                                            <div className="text"> <span className="sup-title">Download from</span> <span className="title">App Store</span> </div>
                                        </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 form-inline">
                                        <div className="form-group p-1"> <a href="https://play.google.com/store/apps/details?id=com.serville.joboy" className="btn btn-market btn--with-shadow"> <img className="utouch-icon" src="../joboyimages/google-play.svg" alt="Joboy on Google play store" />
                                            <div className="text"> <span className="sup-title">Download from</span> <span className="title">Google Play</span> </div>
                                        </a> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb40 pt40">
                <div className="container text-center">
                    <h2>EXPERT PLUMBING SERVICES IN YOUR CITY</h2>
                    <p className="mb-5">Our experienced plumbers can take care of all your plumbing issues from fixing a water leakage to removing drainage blocks. They can also help with fixing your water purifier, fixing a kitchen sink, or periodic maintenance for your home, at affordable prices, using best quality spares and materials.</p>

                    <div className="row justify-content-center">
                        <div className="col-lg-12">

                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-item pd-bottom-30">
                                <div className="thumb">
                                    <img src="../joboyimages/services/kitchen-sink-fix.jpg" alt="Kitchen Sink Blockage" />
                                </div>
                                <div className="featured-item-meta">
                                    <h3>KITCHEN SINK</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-item pd-bottom-30">
                                <div className="thumb">
                                    <img src="../joboyimages/services/water-leakage.jpg" alt="Water Leak Fix" />
                                </div>
                                <div className="featured-item-meta">
                                    <h3>WATER LEAKAGE</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-item pd-bottom-30">
                                <div className="thumb">
                                    <img src="../joboyimages/services/taps-and-showers.jpg" alt="taps and Showers" />
                                </div>
                                <div className="featured-item-meta">
                                    <h3>TAPS & SHOWERS</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section>
                <div className="container text-center">
                    <div className="row">
                        <div className="col-sm-4 pt-5">
                            <ul className="features-list">
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Easy-Scheduling.png" alt="Easy to book" /> </div>
                                <li className="single-feature-item">
                                    <h5> Your Convenient Time</h5>
                                    <span className="text">Your time is precious. Leave the tough work to us.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Verified-Professionals.png" alt="Background verified" /> </div>
                                <li className="single-feature-item">
                                    <h5>Verified Service Partners</h5>
                                    <span className="text">Your safety and security is important for us.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/ServiceWarranty.png" alt="Warranty on services" /> </div>
                                <li className="single-feature-item">
                                    <h5>Service Warranty</h5>
                                    <span className="text">Get upto 60 days service warranty ensuring peace of mind.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/TransparentPricing.png" alt="Pre-defined pricing" />  </div>
                                <li className="single-feature-item">
                                    <h5>Transparent Pricing</h5>
                                    <span className="text">Know in advance what you are paying for.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Secure-Payments.png" alt="Many payment options" /> </div>
                                <li className="single-feature-item">
                                    <h5>Online Payments </h5>
                                    <span className="text">Don't carry cash? No problem, pay online.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Support.png" alt="Best customer care" /> </div>
                                <li className="single-feature-item">
                                    <h5>24 x 7 Support</h5>
                                    <span className="text">Reach us anytime. We are here to support.</span> </li>
                            </ul>
                        </div>
                        <div className="col-sm-8 text-left">
                            <div className="p-5">
                                <h3>Why book plumbers from Joboy?</h3>
                                <div>
                                    <p>We bring you background verified, experienced, plumbers to you, at your most convenient time, with an added service warranty, to give you more confidence. You can be sure that we will give you the best services, and in case something goes wrong with what we have done, we will correct it at our cost. Our plumbing service costs are affordable, and you get to know in advance what you will be paying for. You also have the option to pay by cash or pay online. Rest assured, we have the best plumbers for your home repairs.</p>
                                    <p className="mt-3 mb-5">Book a service online easily, sit back and relax, and let our experts take care of your home.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <ul className="td-list mb-5">
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Best Electricians Near Me" /></i>Bathroom Plumbing Fixtures</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Electrical Repairs Near Me" /></i>Pipe Blockage Clearing</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Electrical wiring fixes" /></i>Kitchen Sink Fixing</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Inverter and stabilizer services" /></i>Water Purifier Fixing</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Fan and air cooler repairs" /></i>Drainage Block Repairs</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="MCB and fuse box repairs" /></i>Expert Plumbers Near Me</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul className="td-list mb-5">
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Switch and sockets repairs" /></i>Tap & Shower Replacements</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Light fixtures repairs" /></i>Toilet Installation</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Room heater repairs" /></i>Water Tank Fixing</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="HVAC electrical fitting" /></i>Water Force Checks</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Wall drilling and hanging" /></i>Water Leakage Checks</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Accessories installation" /></i>Wash Basin Fixing</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ExploreServices />

        </div>
    );
}

export default PlumberNearMe;
import React from "react";

const ExploreServices = () => {

    return (
        <div>
            <section className="mu-service pt40">
                <div className="container text-center">
                    <h4>EXPLORE OUR MOST USED SERVICES</h4>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="features-grid clearfix">
                                <div className="grid"><a href="https://joboy.com/service/hvac-service-near-me">
                                    <div className="icon"><img src="../joboyimages/services/ac-service-near-me.png" alt="AC service near me" className="img-fluid" />  </div>
                                    <h4>AC Service</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="https://joboy.com/service/carpenter-near-me"><div className="icon"> <img src="../joboyimages/services/carpenter-near-me.png" alt="Carpenter near me" className="img-fluid" /> </div>
                                        <h4>Carpenter</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="https://joboy.com/service/plumber-near-me"> <div className="icon"><img src="../joboyimages/services/plumber-near-me.png" alt="Plumber near me" className="img-fluid" /> </div>
                                        <h4>Plumber</h4></a>
                                </div>
                                <div className="grid">
                                    <a href=""> <div className="icon">  <img src="../joboyimages/services/pest-control-near-me.png" alt="Pest control near me" className="img-fluid" /> </div>
                                        <h4>Pest Control</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="https://joboy.com/service/cleaning-service-near-me">  <div className="icon"><img src="../joboyimages/services/deep-cleaning-near-me.png" alt="deep cleaning near me" className="img-fluid" /> </div>
                                        <h4>Deep Cleaning</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="https://joboy.com/service/hvac-service-near-me">   <div className="icon"><img src="../joboyimages/services/home-sanitization-near-me.png" alt="home sanitization near me" className="img-fluid" /> </div>
                                        <h4>Home Sanitisation</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="">   <div className="icon"><img src="../joboyimages/services/web-designing-service-near-me.png" alt="best web design near me" className="img-fluid" /> </div>
                                        <h4>Web Designing</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="https://joboy.com/service/cleaning-service-near-me"><div className="icon"> <img src="../joboyimages/services/sofa-cleaning-service-near-me.png" alt="sofa cleaning services near me" className="img-fluid" /> </div>
                                        <h4>Sofa Cleaning</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="https://joboy.com/service/cleaning-service-near-me">  <div className="icon"> <img src="../joboyimages/services/dry-cleaning-near-me.png" alt="Dry cleaning near me" className="img-fluid" /> </div>
                                        <h4>Dry Cleaning</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="https://joboy.com/service/painter-near-me">   <div className="icon"><img src="../joboyimages/services/painter-near-me.png" alt="Best painters near me" className="img-fluid" /> </div>
                                        <h4>Painter</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="https://joboy.com/Services/"> <div className="icon"><img src="../joboyimages/services/handyman-service-near-me.png" alt="Handyman services near me" className="img-fluid" /> </div>
                                        <h4>Handyman</h4></a>
                                </div>
                                <div className="grid">
                                    <a href="https://joboy.com/services/">  <div className="icon"> <img src="../joboyimages/services/packers-and-movers-near-me.png" alt="packers and movers near me" className="img-fluid" /> </div>
                                        <h4>Packers & Movers</h4></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default ExploreServices;
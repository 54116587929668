import React, {  useState } from "react";

const Contactus = () => {

    const [formData,setFormData] = useState({
        name: '',
        email: '',
        address: ''
    });

    const handleChange = (e) => {

        const {name,value} = e.target;
        
        setFormData({
         ...formData,
         [name]: value
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form data submitted:', formData);
        // You can also send the form data to a server here
      };


    return (
        <div>
            <section> <img src="./joboyimages/inner-banner.jpg" className="img-fluid" alt="" /> </section>
            <section className="pt40 pb40">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="contact-wrapper">
                                <h1 className="pb15 text-center h1">Send Us A Message</h1>
                                <div className="row">

                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <label>Name</label>
                                            <input type="text" name="name" value={formData.name} onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>Email</label>
                                            <input type="email" name="email" value={formData.email} onChange={handleChange} />
                                        </div>
                                        <div>
                                            <label>Address</label>
                                            <textarea name="address" value={formData.address} onChange={handleChange} ></textarea>
                                        </div>
                                        <button type="submit">Submit</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Contactus;
import React from "react";

const FooterCity = () => {

    return (
       <section className="gray-bg pb40 pt40">
       <div className="container">
         <h6 className="text-center pt15 pb40 text-uppercase">Our services are available in major cities, across the globe</h6>
         <div className="inner-demo">
           <ul className="joboy-city">
           <li> <a href="https://joboy.ae"><img src="./joboyimages/dubai.png" className="img-fluid" alt="Best Home Services in Dubai" />
               <div>Dubai</div>
             </a> </li>
             {/* <li> <a href="https://joboy.uk/home/greater-london"><img src="./joboyimages/LondonUK.png" className="img-fluid" alt="Best Home Services in London" />
               <div>London</div>
             </a> </li> */}
             <li> <a href="https://joboy.in/home/delhi-ncr"><img src="./joboyimages/Delhi.png" className="img-fluid" alt="Best Home Services in Delhi" />
               <div>Delhi</div>
             </a> </li>
             <li> <a href="https://joboy.az/home/Baku"><img src="./joboyimages/Baku.png" className="img-fluid" alt="Best Home Services in Baku" />
               <div>Baku</div>
             </a> </li>
             <li> <a href="https://joboy.ae/home/sharjah"><img src="./joboyimages/Sharjah.png" className="img-fluid" alt="Best Home Services in Sharjah" />
          <div>Sharjah</div>
          </a> </li>
             <li> <a href="https://joboy.in/home/trivandrum"><img src="./joboyimages/trivandrum.png" className="img-fluid" alt="Best Home Services in Trivandrum" />
               <div>Trivandrum</div>
             </a></li>
             <li> <a href="https://joboy.in/home/chennai"><img src="./joboyimages/chennai.png" className="img-fluid" alt="Best Home Services in Chennai" />
               <div>Chennai</div>
             </a></li>
             <li> <a href="https://joboy.in/home/kozhikode"><img src="./joboyimages/kozhikode.png" className="img-fluid" alt="Best Home Services in Kozhikode" />
               <div>Kozhikode</div>
             </a></li>
             <li> <a href="https://joboy.in/home/mumbai"><img src="./joboyimages/mumbai.png" className="img-fluid" alt="Best Home Services in Mumbai" />
               <div>Mumbai</div>
             </a></li>
             <li> <a href="https://joboy.in/home/bengaluru"><img src="./joboyimages/bangalore.png" className="img-fluid" alt="Best Home Services in Bangalore" />
               <div>Bangalore </div>
             </a></li>
             <li> <a href="https://joboy.in/home/kochi"><img src="./joboyimages/Kochi.png" className="img-fluid" alt="Best Home Services in Kochi" />
               <div>Kochi</div>
             </a></li>
             <li> <a href="https://joboy.in/home/hyderabad"><img src="./joboyimages/hyderabad.png" className="img-fluid" alt="Best Home Services in Hyderabad" />
               <div>Hyderabad</div>
             </a></li>
             <li> <a href="https://joboy.ae"><img src="./joboyimages/Abudhabi.png" className="img-fluid" alt="Best Home Services in Abu Dhabi" />
               <div>Abu Dhabi</div>
             </a> </li>
           </ul>
           <div className="clearfix"></div>
         </div>
       </div>
     </section>
    );


}

export default FooterCity;
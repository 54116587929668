import React from "react";
import { Link } from 'react-router-dom';

import FooterCity from './FooterCity';


const Services = () => {

 
    return (
        <div><section> <img src="./joboyimages/inner-banner.jpg" className="img-fluid" alt="Joboy Home Services"/> </section>
        <section className="pb40 pt40">
          <div className="text-center service-bg">
            <h1 className="serviceh1">Home Maintenance, Renovation, Appliance Repairs, <span>Cleaning, Lifestyle Services,</span> </h1>
            <h2 className="text-uppercase serviceh2">Joboy For Everything!</h2>
          </div>
          <div className="container">
            <div className="row">
              <div className="text-center"> Whatever your requirement may be, whether it is home repairs & maintenance or cleaning, need a personal trainer or want to set up a business, need a cake delivered or want to move to a different city, want to redo your interiors or need a new website, Joboy has an expert for everything. Well, almost everything! We completely take away the pain of finding the right service expert and the continuous follow up with them, and get your chores done on time, while leaving you to take care of the more important things in life. All this at affordable prices and with service warranties. Isn't that wonderful? </div>
            </div>
          </div>
        </section>
        <section className="gray-bg pb40 pt40">
          <div className="container">
            <div className="pb40">
              <center>
                <h2 className="city-head ">HOME MAINTENANCE</h2>
              </center>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="pb15">Electrician, Plumber, Carpenter, AC Service, or any other home services, our service partners are selected after verification of their experience and background. They are well-trained and with the ability to work beyond your expectations, Joboy is the obvious choice for any home maintenance requirements.</p>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><Link to="/services/electricianNearMe"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Electrician Near Me"/></i> Electrical Services</Link></li>
                      <li><Link to="/services/plumberNearMe"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Plumber Near Me"/></i> Plumbing Services</Link></li>
                      <li><Link to="/services/carpenterNearMe"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Carpenter Near Me"/></i> Carpentry Services</Link></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Painter Near Me"/></i> Painting Services</a></li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Pest Control"/></i> Pest Control</a></li>
                      <li><Link to="/services/hvacServiceNearMe"><i><img src="./joboyimages/tck-mark.png" alt="Joboy AC Services"/></i> HVAC Service</Link></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Packers and Movers"/></i> Packers & Movers</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Home Sanitisation"/></i> Home Sanitisation</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6"><img src="./joboyimages/HOME-MAINTENANCE.png" className="img-fluid" alt="Joboy Home Maintenance Services"/></div>
            </div>
          </div>
        </section>
        <section className="pb40 pt40">
          <div className="container">
            <div className="pb40">
              <center>
                <h2 className="city-head ">CLEANING SERVICES</h2>
              </center>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6"><img src="./joboyimages/CLEANING-SERVICES.png" className="img-fluid" alt="Joboy Cleaning Services"/></div>
              <div className="col-md-6">
                <p className="pb15">Our home cleaning services, give you multiple options. It could just be a kitchen or a bathroom that needs cleaning, or you may want the whole house cleaned, or an office space that needs cleaning. Don't worry, our experts will give your space its lost glory using the most suitable equipments and products.</p>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Deep Cleaning Services"/></i> Home Deep Cleaning</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Kitchen Cleaning Services"/></i> Kitchen Cleaning </a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Bathroom Cleaning Services"/></i> Bathroom Cleaning</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Sofa Cleaning Services"/></i> Sofa Cleaning</a></li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Car Detailing"/></i> Car Detailing</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Carpet Cleaning"/></i> Carpet Cleaning</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Laundry and Ironing"/></i> Laundry & Ironing</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Joboy Dry Cleaning"/></i>Dry Cleaning</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="gray-bg pb40 pt40">
          <div className="container">
            <div className="pb40">
              <center>
                <h2 className="city-head ">HOME APPLIANCE REPAIRS</h2>
              </center>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="pb15">Blacked out television or a non-cooling refigerator, a choked chimney or a virus infected laptop, our experts can solve all your appliance and electronic device issues, with professional care and warrantied replacements. Their attention to detail and the ability to work beyond your expectations, will blow your mind.</p>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Television Repairs"/></i> Television</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Fridge Repairs"/></i> Refrigerator</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Washing Machine Repairs"/></i> Washing Machine</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Microwave Oven Repairs"/></i> Microwave Oven</a></li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="CCTV Repairs"/></i> CCTV</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Laptop and Desktop Repairs"/></i> Laptop & Computers</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Printer Repair"/></i> Printers</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Stove & Chimney Repair"/></i> Hob & Chimney</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6"><img src="./joboyimages/HOME-APPLIANCE-REPAIRS.png" className="img-fluid" alt="Joboy Home Appliances Repairs"/></div>
            </div>
          </div>
        </section>
        <section className="pb40 pt40">
          <div className="container">
            <div className="pb40">
              <center>
                <h2 className="city-head ">HOME RENOVATION</h2>
              </center>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6"><img src="./joboyimages/HOME-RENOVATION.png" className="img-fluid" alt="Joboy Home Renovation Services"/></div>
              <div className="col-md-6">
                <p className="pb15">How do you trust someone to hand over the keys of your dear home, to rebuild or modify? Trust someone recommended by Joboy many with years of experience. Our partners will take care of your walls, your floors, your roof, your garden, or your furniture, and all other installations, as carefully as you would.</p>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Roofing Services"/></i> Roofing</a></li>
                      <li><a href="https://joboy.az"><i><img src="./joboyimages/tck-mark.png" alt="Wall Plastering"/></i> Wall Plastering</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Painting Services"/></i> Painting</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Gardening Services"/></i> Gardening</a></li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Mason Services"/></i> Masonry</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Carpentry Services"/></i> Carpentry</a></li>
                      <li><a href="https://joboy.az"><i><img src="./joboyimages/tck-mark.png" alt="Flooring and Tile Work"/></i> Flooring</a></li>
                      <li><a href="https://joboy.az"><i><img src="./joboyimages/tck-mark.png" alt="Interior Decorations"/></i> Interior Decoration</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="gray-bg pb40 pt40">
          <div className="container">
            <div className="pb40">
              <center>
                <h2 className="city-head ">HEALTH, BEAUTY, & FITNESS</h2>
              </center>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="pb15">When you need a doctor in an urgent situation, a salon service for an unexpected event, a personal trainer during a difficult time, whom do you turn to? Joboy has the best professionals in all these areas, with many years of experience, to help you when you need them.</p>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt=""/></i> Fitness Trainer</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt=""/></i> Doctor at Home</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt=""/></i> Yoga Trainer</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt=""/></i> Parental Care</a></li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt=""/></i> Hair Cuts</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt=""/></i> Salon Services</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt=""/></i> Hair Care</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt=""/></i> Cakes & Bouquets</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6"><img src="./joboyimages/health_and_fitness.png" className="img-fluid" alt="Joboy Beauty and Fitness Services"/></div>
            </div>
          </div>
        </section>
        <section className="pb40 pt40">
          <div className="container">
            <div className="pb40">
              <center>
                <h2 className="city-head ">BUSINESS SERVICES</h2>
              </center>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6"><img src="./joboyimages/BUSINESS-SERVICES.png" className="img-fluid" alt="Joboy Business Services"/></div>
              <div className="col-md-6">
                <p className="pb15">Starting a new business, entering into a new partnership, submission of your tax returns, or buying a new property, when you need services of a qualified professional, how do you find them? Joboy brings you the most experienced professionals, at affordable fees, right when you need help.</p>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Accounting Services"/></i> Accounting Services</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Tax Management Services"/></i> Tax Services</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Business Set Up"/></i> Business Setup</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Trademark Registration"/></i> Trademark</a></li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Private Investigation"/></i> Private Investigation</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Background Verification"/></i> Background Check</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Legal Services"/></i> Legal Services</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Personal Assistants"/></i> Personal Assistants</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="gray-bg pb40 pt40">
          <div className="container">
            <div className="pb40">
              <center>
                <h2 className="city-head ">TECHNOLOGY SERVICES</h2>
              </center>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="pb15">Whether it is designing a new website for your business, or creating a complex mobile application to deal with the digital era, or reaching out to more customers through digital marketing services, we have the best partners who will give you the most professional experience, at low prices. Trust us, after all, we also belong in the digital world.</p>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Mobile Applications"/></i> Mobile Application</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="E-commerce websites"/></i> E-commerce Platform</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Website design service"/></i> Website Design</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Server management"/></i> Server Management</a></li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="td-list mb-20">
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Domain hosting service"/></i> Domain Hosting</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Digital Marketing Services"/></i> Digital Marketing</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Search Engine Optimisation"/></i> SEO Services</a></li>
                      <li><a href="https://joboy.in"><i><img src="./joboyimages/tck-mark.png" alt="Brand Design Services"/></i> Brand Designs</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6"><img src="./joboyimages/TECHNOLOGY-SERVICES.png" className="img-fluid" alt="Joboy Technology Services"/></div>
            </div>
          </div>
        </section>
        <FooterCity />
        </div>
    );
}

export default Services;
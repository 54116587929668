import react from 'react';

import FooterCity from './FooterCity';

const Careers = () => {

  return (
    <div>
      <section> <img src="./joboyimages/inner-banner.jpg" className="img-fluid" alt="" /> </section>
      <section className="pb40 pt40">
        <div className="container">
          <h1 className="h1-about text-center pt20 pb30">Why should you work for Joboy?</h1>
          <h5 className="text-center pt30 pb30">Whatever the job maybe, however big or small, wherever it may be,</h5>
          <h6 className="text-center pt30 pb40">our priority is to deliver superior quality and value-for-money experiences to our customers, wherever they are. If you think you value time, quality, attention to detail, and extreme customer focus, join our team of highly motivated and focused professionals.</h6>
          <div id="accordion" className="accordion">
            <div className="card mb-0">
              <div className="card-header collapsed" data-toggle="collapse" href="#collapseOne"> <a className="card-title"> Business Development & Partner Relations Executive </a>
                <button className="btn book-now-btn float-right" type="submit">Read More</button>
              </div>
              <div id="collapseOne" className="card-body collapse" data-parent="#accordion">
                <div className="row">
                  <div className="col-md-8">
                    <p>Looking for dynamic and passionate champions who can help us with our expansion plans in Delhi, Bangalore, Hyderabad and Mumbai locations. We will provide in-depth training on our procedures and help you get real market experience which may help you in your business development career.</p>
                    <h3 className="pt15 pb15">Key Responsibilities</h3>
                    <ol className="ollist">
                      <li>Understand the service requirements and identify the service partners within the city limits.</li>
                      <li>Meet and on-board service partners and complete on-boarding and training procedures within defined TAT.</li>
                      <li>Coordinate with service providers and ensure that they continue as valued service partners.</li>
                      <li>Identify partners for our ecommerce and deals platforms and improve business propositions.</li>
                      <li>Prepare product database for ecommerce platform and compare product availability and positioning by competitors.</li>
                      <li>Identify gaps in existing business services, document methods of improvement, and help business team to implement.</li>
                      <li>Locations: Bangalore, Hyderabad, Delhi, Mumbai</li>
                    </ol>
                    <strong>Send your resume to <a href="recruitment@serville.in">recruitment@serville.in</a></strong> </div>
                  <div className="col-md-4">
                    <div className="gray-bg fp20 mmb20">
                      <ul className="career-ul">
                        <li> <span className="fa fa-bullseye"></span> <a href="#">Salary range: <strong>Best in industry</strong></a> </li>
                        <li> <span className="fa fa-bullseye"></span> <a href="#">Nature of Job: <strong>Local travel required</strong></a> </li>
                        <li> <span className="fa fa-bullseye"></span> <a href="#">Experience: <strong>1-3 years</strong></a> </li>
                        <li> <span className="fa fa-bullseye"></span> <a href="#">Qualification: <strong>Bachelor's Degree</strong></a> </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo"> <a className="card-title"> Operations and Customer Support Executive </a>
                <button className="btn book-now-btn float-right" type="submit">Read More</button>
              </div>
              <div id="collapseTwo" className="card-body collapse" data-parent="#accordion">
                <div className="row">
                  <div className="col-md-8">
                    <h3 className="pt15 pb15">Key Requirements</h3>
                    <ol className="ollist">
                      <li>Possess excellent customer/client management skills (Communication and convincing skill).</li>
                      <li>Experience of interacting with various stakeholders.</li>
                      <li>Good coordination and negotiation skills</li>
                      <li>Knowledge of various computer applications.</li>
                      <li>Added experience for generation of various reports.</li>
                      <li>Commercial acumen and basic financial knowledge</li>
                      <li>Experience working in a call center/customer care environment</li>
                      <li>Excellent service mentality</li>
                      <li>Ability to speak multiple languages will be an advantage</li>
                      <li>Location: Kochi, Kerala</li>
                    </ol>
                    <strong>Send your resume to <a href="recruitment@serville.in">recruitment@serville.in</a></strong> </div>
                  <div className="col-md-4">
                    <div className="gray-bg fp20 mmb20">
                      <ul className="career-ul">
                        <li> <span className="fa fa-bullseye"></span> <a href="#">Salary range: <strong>Best in industry</strong></a> </li>
                        <li> <span className="fa fa-bullseye"></span> <a href="#">Nature of Job: <strong>In-Office Job</strong></a> </li>
                        <li> <span className="fa fa-bullseye"></span> <a href="#">Experience: <strong>1-3 years</strong></a> </li>
                        <li> <span className="fa fa-bullseye"></span> <a href="#">Qualification: <strong>Graduate/Post-Graduate</strong></a> </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree"> <a className="card-title"> Assistant Manager - Operations and Customer Support</a>
                <button className="btn book-now-btn float-right" type="submit">Read More</button>
              </div>
              <div id="collapseThree" className="collapse" data-parent="#accordion">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8">
                      <h3 className="pt15 pb15">Key Requirements</h3>
                      <ol className="ollist">
                        <li>Excellent customer management skills (Communication and convincing skill)</li>
                        <li>Should have good communication skills in English and minimum one regional language</li>
                        <li>Should have minimum 3 years experience in operations management or customer service</li>
                        <li>Should have good knowledge in creating operational reports</li>
                        <li>Prior Experience of managing and mentoring a team</li>
                        <li>Commercial acumen and basic financial knowledge</li>
                        <li>Excellent service mentality</li>
                        <li>Candidate should be proficient in MS Word, Excel and Powerpoint.</li>
                      </ol>
                      <strong>Send your resume to <a href="recruitment@serville.in">recruitment@serville.in</a></strong> </div>
                    <div className="col-md-4">
                      <div className="gray-bg fp20 mmb20">
                        <ul className="career-ul">
                          <li> <span className="fa fa-bullseye"></span> <a href="#">Salary range: <strong>Best in industry</strong></a> </li>
                          <li> <span className="fa fa-bullseye"></span> <a href="#">Nature of Job: <strong>In-Office Job</strong></a> </li>
                          <li> <span className="fa fa-bullseye"></span> <a href="#">Experience: <strong>3-6 years</strong></a> </li>
                          <li> <span className="fa fa-bullseye"></span> <a href="#">Qualification: <strong>Graduate/Post-Graduate</strong></a> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapsefour"> <a className="card-title">Business Development Executive </a>
                <button className="btn book-now-btn float-right" type="submit">Read More</button>
              </div>
              <div id="collapsefour" className="collapse" data-parent="#accordion">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8">
                      <h3 className="pt15 pb15">Key Requirements</h3>
                      <ol className="ollist">
                        <li>Should have good communication skills in English and Malayalam</li>
                        <li>Possess good convincing and negotiation skills</li>
                        <li>Minimum 1 year of experience in a sales profile in IT firms, ecommerce, insurance, retail sector or selling banking products</li>
                        <li>Candidate should be proficient in MS Word, Excel and Presentation</li>
                        <li>Local candidates preferred</li>
                        <li>Locations: Kochi, Trivandrum, Kozhikode</li>
                      </ol>
                      <strong>Send your resume to <a href="recruitment@serville.in">recruitment@serville.in</a></strong> </div>
                    <div className="col-md-4">
                      <div className="gray-bg fp20 mmb20">
                        <ul className="career-ul">
                          <li> <span className="fa fa-bullseye"></span> <a href="#">Salary range: <strong>Best in industry</strong></a> </li>
                          <li> <span className="fa fa-bullseye"></span> <a href="#">Nature of Job: <strong>Local Travel required</strong></a> </li>
                          <li> <span className="fa fa-bullseye"></span> <a href="#">Experience: <strong>1-3 years</strong></a> </li>
                          <li> <span className="fa fa-bullseye"></span> <a href="#">Qualification: <strong>Graduate/Post-Graduate</strong></a> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterCity />

    </div>
  )

}

export default Careers;
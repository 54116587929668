
import React from 'react';

import FooterCity from './FooterCity';

const About = () => {


  return (
    <div>
      <section> <img src="./joboyimages/inner-banner.jpg" className="img-fluid" alt="" /> </section>
      <section className="pt40 pb40">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about">
                <h1 className="h1-about">We saw people struggling to find
                  service experts around them with the right skills</h1>
                <h5><strong>That is where our journey started</strong></h5>
              </div>
            </div>
            <div className="col-md-6   left-border">
              <div className="pt15 pb15">
                <p className="pb15">We have been in your shoes, we have struggled too, and we built Joboy with a strong base of integrity and ethics, from our experience and your continuous feedback. Using technology to iron out the small difficulties in a broken process, has helped us make huge leaps of change. And by listening to you, we improve, we change, and we grow, all the while helping you focus on all those more important things in your life.</p>
                <button className="btn book-now-btn text-uppercase" type="submit"> VIEW OUR SERVICES </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="services pb40">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 pd_0">
              <div className="iconbox3 first">
                <div className="iconbox_wrapper">
                  <div className="iconbox_image">
                    <div className="iconbox_icon"> <img className="first_icon" src="./joboyimages/Verified-Professionals.png" alt="background verified professionals" /> </div>
                  </div>
                  <div className="iconbox_content">
                    <h3>Verified Professionals</h3>
                    <p>Our partners are well experienced and background verified to ensure you get the best.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pd_0">
              <div className="iconbox3 second">
                <div className="iconbox_wrapper">
                  <div className="iconbox_image">
                    <div className="iconbox_icon"> <img className="first_icon" src="./joboyimages/TransparentPricing.png" alt="simple transparent pricing" /> </div>
                  </div>
                  <div className="iconbox_content">
                    <h3>Transparent Pricing</h3>
                    <p>You will know from us in advance what you will be paying for, and how much it would be.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pd_0">
              <div className="iconbox3 third">
                <div className="iconbox_wrapper">
                  <div className="iconbox_image">
                    <div className="iconbox_icon"> <img className="first_icon" src="./joboyimages/ServiceWarranty.png" alt="warranty on joboy services" /> </div>
                  </div>
                  <div className="iconbox_content">
                    <h3>Service Warranty</h3>
                    <p>We promise to give you the best service, to ensure that we perform well, we also add in a service warranty.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pd_0">
              <div className="iconbox3 four">
                <div className="iconbox_wrapper">
                  <div className="iconbox_image">
                    <div className="iconbox_icon"> <img className="first_icon" src="./joboyimages/Easy-Scheduling.png" alt="easy joboy work scheduling" /> </div>
                  </div>
                  <div className="iconbox_content">
                    <h3>Easy Scheduling</h3>
                    <p>Our platform is so easy to use, we give you more time to relax, with peace of mind.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pd_0">
              <div className="iconbox3 five">
                <div className="iconbox_wrapper">
                  <div className="iconbox_image">
                    <div className="iconbox_icon"> <img className="first_icon" src="./joboyimages/Secure-Payments.png" alt="multiple Joboy payment options" /> </div>
                  </div>
                  <div className="iconbox_content">
                    <h3>Secure Payments</h3>
                    <p>You get multiple payments options. Use our secure, certified, online payments.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pd_0">
              <div className="iconbox3 second">
                <div className="iconbox_wrapper">
                  <div className="iconbox_image">
                    <div className="iconbox_icon"> <img className="first_icon" src="./joboyimages/Support.png" alt="Joboy support team around the clock" /> </div>
                  </div>
                  <div className="iconbox_content">
                    <h3>24/7 Support</h3>
                    <p>We are here, round the clock, right when you need us. Get service at your convenience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fun-fact-area text-center bg-fixed shadow dark-hard pt40 pb40 bgabout">
        <div className="container">
          <h2 className="pb40 h1">How Joboy Works</h2>
          <div className="row">
            <div className="col-md-4 item">
              <div className="fun-fact">
                <div className="timer" data-to="38" data-speed="5000">01</div>
                <span className="medium">Book a service</span>
                <p> Select from our wide range of services in your city on web/mobile app, review prices, set location, date, time, and payment mode as per your convenience. </p>
              </div>
            </div>
            <div className="col-md-4 item">
              <div className="fun-fact">
                <div className="timer" data-to="2348" data-speed="5000">02</div>
                <span className="medium">Service Delivery</span>
                <p> Our service experts will contact you for any additional information, and will be there at the scheduled time. Sit back and relax while our expert delivers the service. </p>
              </div>
            </div>
            <div className="col-md-4 item">
              <div className="fun-fact">
                <div className="timer" data-to="50" data-speed="5000">03</div>
                <span className="medium">Pay & Leave Feedback</span>
                <p> On completion of service, pay via the selected payment mode, and a receipt will be generated. We would highly appreciate if you can leave your feedback. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterCity />
    </div>
  );
}

export default About;
